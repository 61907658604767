import styled from 'styled-components';
import { screenSize } from '@/styles/ScreenSizes';

export const PostGrid = styled.div`
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	padding: 80px 24px 40px;

	overflow: hidden;

	${screenSize.md} {
		grid-template-columns: repeat(2, 1fr);
	}

	${screenSize.sm} {
		display: block;
	}

	img {
		width: 100%;
	}
`;
