import { screenSize } from '@/styles/ScreenSizes';
import { backgroundPatternInverted } from '@/styles/styleConstants';
import { useRouter } from 'next/router';
import styled from 'styled-components';

const Wrapper = styled.div`
	margin-top: 120px;
	padding: 72px 24px 48px;

	background-color: var(--dark-color);
	${backgroundPatternInverted};
`;

const Container = styled.div`
	display: flex;
	align-items: flex-end;
	gap: 40px;
	color: var(--light-color);

	h2 {
		font-size: var(--regular-font-size);
		line-height: var(--regular-line-height);
		color: var(--light-color);
	}

	${screenSize.md} {
		flex-wrap: wrap;
	}

	${screenSize.sm} {
		flex-direction: column;
		align-items: flex-start;
	}
`;

const Textbox = styled.div`
	display: inline-block;
	padding: 0 24px;
	margin-bottom: -12px;

	background-color: var(--dark-color);

	h2,
	p {
		margin: 12px 0;
	}
`;

const HomepageLink = styled.a`
	display: block;
	background-color: white;
	color: black;
	font-weight: 500;
	padding: 22px;
	width: 182px;
	text-align: center;

	${screenSize.sm} {
		margin-left: 16px;
	}
`;

const Socials = styled.div`
	display: flex;
	gap: 24px;
	background-color: black;
	padding: 8px 16px;
`;

const Colophon = styled.div`
	margin-left: auto;
	margin-right: 40px;
	background-color: black;
	padding: 24px;

	${screenSize.md} {
		margin-left: 0;
	}
`;

export const Footer = () => {
	const { locale } = useRouter();

	return (
		<Wrapper>
			<Container>
				<Textbox>
					<h2>{locale === 'et' ? 'Kontaktid' : 'Contact'}</h2>
					<p>
						+372 625 9300
						<br />
						stat@stat.ee
					</p>
				</Textbox>
				<HomepageLink href="https://www.stat.ee/" target="_blank" rel="noreferrer">
					{locale === 'et' ? 'Statistikaameti koduleht' : 'Webpage of Statistics Estonia'}
				</HomepageLink>
				<Socials>
					<a href="https://www.facebook.com/Statistikaamet" target="_blank" rel="noreferrer">
						<img src="/icons/facebook.svg" alt="" />
					</a>
					<a href="https://twitter.com/eestistatistika" target="_blank" rel="noreferrer">
						<img src="/icons/twitter.svg" alt="" />
					</a>
					<a
						href="https://www.linkedin.com/company/statistikaamet-statistics-estonia/"
						target="_blank"
						rel="noreferrer"
					>
						<img src="/icons/linkedin.svg" alt="" />
					</a>
					<a href="https://www.youtube.com/user/Statistikaamet" target="_blank" rel="noreferrer">
						<img src="/icons/youtube.svg" alt="" />
					</a>
					<a href="https://www.instagram.com/eesti_statistika" target="_blank" rel="noreferrer">
						<img src="/icons/instagram.svg" alt="" />
					</a>
				</Socials>
				<Colophon>
					{locale === 'en' ? (
						<>
							<h2>Contributors</h2>
							Curators and text authors: <strong>Olev Liivik and Hiljar Tammela</strong>
							<br />
							Consultant: <strong>Veiko Berendsen</strong>
							<br />
							Copy editor: <strong>Kairit Saar</strong>
							<br />
							Translators: <strong>Kati Coleman and Karin Sahk</strong>
							<br />
							Photographer: <strong>Uku Nurges</strong>
							<br />
							Built by: <strong>Platvorm OÜ</strong>
							<br />
							Designed by: <strong>Polaar Studio OÜ</strong>
							<br />
							Project manager: <strong>Liis Meriküll</strong>
						</>
					) : (
						<>
							<h2>Näituse koostajad</h2>
							Kuraatorid ja tekstide autorid: <strong>Olev Liivik ja Hiljar Tammela</strong>
							<br />
							Konsultant: <strong>Veiko Berendsen</strong>
							<br />
							Keeletoimetaja: <strong>Kairit Saar</strong>
							<br />
							Tõlkijad: <strong>Kati Coleman ja Karin Sahk</strong>
							<br />
							Fotograaf: <strong>Uku Nurges</strong>
							<br />
							Teostus: <strong>Platvorm OÜ</strong>
							<br />
							Kujundus: <strong>Polaar Studio OÜ</strong>
							<br />
							Projektijuht: <strong>Liis Meriküll</strong>
						</>
					)}
				</Colophon>
			</Container>
		</Wrapper>
	);
};
