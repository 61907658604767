import { css } from 'styled-components';

export const backgroundPattern = css`
  background-image: url('/media/images/sa-pattern-black.svg');
  background-repeat: repeat;
`;

export const backgroundPatternInverted = css`
  background-image: url('/media/images/sa-pattern-white.svg');
  background-repeat: repeat;
`;
