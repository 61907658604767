import React, { FC } from 'react';
import styled from 'styled-components';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { screenSize } from '@/styles/ScreenSizes';
import { BrandLogo } from '@/components/icons/BrandLogo';
import { BrandLogoEng } from '@/components/icons/BrandLogoEng';
import { backgroundPattern } from '@/styles/styleConstants';
import { LocaleSwitcher } from '../LocaleSwitcher';

interface ContainerProps {
	background?: boolean;
}

const Container = styled.div<ContainerProps>`
	position: sticky;
	top: 0;
	left: 0;
	right: 0;
	z-index: 2;

	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	padding: 4px 32px 0;

	background-color: white;
	${backgroundPattern};

	${screenSize.sm} {
		padding: 4px 16px 0 8px;
	}
`;

const LogoWrapper = styled.div`
	width: 140px;
	background-color: white;
`;

const TopBarTitle = styled.span`
	font-size: 36px;
	font-weight: 500;
	color: black;
	text-align: center;
	background-color: white;
	padding: 16px;

	${screenSize.md} {
		font-size: 24px;
	}

	${screenSize.sm} {
		font-size: 16px;
	}
`;

export const TopBar: FC = () => {
	const { locale } = useRouter();

	return (
		<Container>
			<LogoWrapper>
				<Link href="/">
					<a>{locale === 'et' ? <BrandLogo /> : <BrandLogoEng />}</a>
				</Link>
			</LogoWrapper>
			<TopBarTitle>{locale === 'et' ? '100 aastat Eesti statistikat' : '100 Years of Estonian Statistics'}</TopBarTitle>
			<LocaleSwitcher />
		</Container>
	);
};
