import React from 'react';
import type { GetStaticProps, NextPage } from 'next';
import { ArticleService } from '@/utils/services/ArticleService';
import { TopBar } from '@/components/layout/TopBar';
import { Post } from '@/components/list/Post';
import { Background } from '@/components/layout/Background';
import { PostGrid } from '@/components/list/PostGrid';
import { PageMeta } from '@/components/PageMeta';

const Home: NextPage<HomeProps> = ({ posts }) => {
	return (
		<>
			<PageMeta />
			<Background />
			<TopBar />
			<PostGrid>
				{posts?.map((post) => <Post key={post.path} post={post} />)}
			</PostGrid>
		</>
	);
};

export const getStaticProps: GetStaticProps = async ({ locale }) => {
	const api = new ArticleService(locale);
	const allPosts = await api.getAllPosts();

	return {
		props: {
			posts: allPosts,
		},
	};
};

export default Home;
