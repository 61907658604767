import { createGlobalStyle, css } from 'styled-components';
import { screenSize } from './ScreenSizes';

const globalStyles = css`
	:root {
		--content-width: 1300px;
		--header-height: 60vh;

		--larger-font-size: 22px;
		--larger-line-height: 28px;
		--regular-font-size: 18px;
		--regular-line-height: 22px;
		--small-font-size: 13px;
		--small-line-height: 16px;

		--text-color: #595959;
		--dark-color: black;
		--light-color: white;

		${screenSize.mdlg} {
			--header-height: 80vh;
		}

		${screenSize.sm} {
			--header-height: auto;
		}
	}

	* {
		box-sizing: border-box;
	}

	html,
	body {
		padding: 0;
		margin: 0;
		font-family: Roboto, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans,
			Droid Sans, 'Helvetica Neue', sans-serif;
		min-width: 100%;
		min-height: 100%;
		font-size: 16px;
		line-height: 20px;
		color: var(--text-color);

		.pswp__bg {
			background-color: white;
		}

		.pswp__ui--fit .pswp__top-bar,
		.pswp__top-bar {
			background-color: transparent;
		}

		.pswp__button {
			filter: invert();
		}
	}

	h1,
	h2,
	h3 {
		color: black;
	}

	h1 {
		font-size: 64px;
		line-height: 1;

		${screenSize.sm} {
			font-size: 32px;
			line-height: 1.1;
		}
	}

	a {
		color: inherit;
		text-decoration: none;
	}

	img {
		width: 100%;
	}
`;

export const GlobalStyles = createGlobalStyle`
  ${globalStyles}
`;
