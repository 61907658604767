import React from 'react';
import type { AppProps } from 'next/app';
import { GlobalStyles } from '@/styles/GlobalStyles';
import { Footer } from '@/components/layout/Footer';
import SimpleReactLightbox from 'simple-react-lightbox'
import { GlobalHead } from '@/components/GlobalHead';

import 'react-photoswipe/lib/photoswipe.css';

function MyApp({ Component, pageProps }: AppProps) {
	return (
		<>
			<GlobalHead />
			<GlobalStyles />
			<SimpleReactLightbox>
				<Component {...pageProps} />
			</SimpleReactLightbox>
			<Footer />
		</>
	);
}

export default MyApp;
