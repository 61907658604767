import React, { FC } from 'react';
import Head from 'next/head';
import { useRouter } from 'next/router';

interface PageMetaProps {
	articleMeta?: ArticleMeta;
}

export const PageMeta: FC<PageMetaProps> = ({ articleMeta }) => {
	const { locale } = useRouter();

	const url = 'https://100.stat.ee';

	const defaultMeta = [
		{
			lang: 'et',
			title: 'Eesti Statistika 100',
			description: 'Kirjeldus',
			image: '/media/images/sa100_meta_et.png',
		},
		{
			lang: 'en',
			title: 'Statistics Estonia 100',
			description: 'Description',
			image: '/media/images/sa100_meta_en.png',
		},
	];

	if (articleMeta && !articleMeta.description) {
		articleMeta.description = defaultMeta.find((meta) => meta.lang === locale)?.description;
	}

	const meta = articleMeta || defaultMeta.find((meta) => meta.lang === locale);

	const metaTitle = meta?.title.replace('&shy;', '');

	return (
		<Head>
			<title>{metaTitle}</title>
			<meta name="title" content={metaTitle} />
			<meta name="description" content={meta?.description} />

			<meta property="og:type" content="website" />
			<meta property="og:url" content={url} />
			<meta property="og:title" content={metaTitle} />
			<meta property="og:description" content={meta?.description} />
			<meta property="og:image" content={`${url}${meta?.image}`} />

			<meta property="twitter:card" content="summary_large_image" />
			<meta property="twitter:url" content={url} />
			<meta property="twitter:title" content={metaTitle} />
			<meta property="twitter:description" content={meta?.description} />
			<meta property="twitter:image" content={`${url}${meta?.image}`} />
		</Head>
	);
};
