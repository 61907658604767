import React, { FC } from 'react';
import Link from 'next/link';
import styled from 'styled-components';
import { screenSize } from '@/styles/ScreenSizes';
// @ts-ignore
import Tilt from 'react-tilt';
import { ImageStack, ImageWrapper } from '../ImageStack';

const titlePositions: Record<string, string> = {
	left: 'row-reverse',
	bottom: 'column',
	top: 'column-reverse',
	unset: 'unset',
};

const getTitlePosition = (position: string = 'unset') => {
	return titlePositions[position] || titlePositions.unset;
};

const Title = styled.span`
	background-color: var(--light-color);
	padding: 4px;
	font-weight: 500;
	text-align: center;
	margin: 24px;
	flex: 1 0 auto;

	${screenSize.sm} {
		margin: 0;
	}
`;
interface ContainerProps {
	width: string;
	rotation: string;
	titlePosition?: string;
}

const Container = styled.div<ContainerProps>`
	margin-bottom: 40px;
	justify-self: center;
	width: ${(p) => p.width};

	${screenSize.sm} {
		width: 100%;
	}

	a {
		position: relative;
		display: flex;
		flex-direction: ${(p) => getTitlePosition(p.titlePosition)};
		align-items: center;

		font-size: var(--larger-font-size);
		line-height: var(--larger-line-height);
		color: var(--dark-color);

		${screenSize.sm} {
			flex-direction: column;
		}
	}

	img {
		transform: rotate(${(p) => p.rotation});

		${screenSize.sm} {
			margin-bottom: 24px;
		}
	}

	${ImageWrapper} {
		margin-left: 0;
	}

	${Title} {
		width: ${(p) => (p.titlePosition === 'left' ? '200px' : '15vw')};

		${screenSize.md} {
			width: ${(p) => (p.titlePosition === 'left' ? '200px' : '40vw')};
		}

		${screenSize.sm} {
			width: 80vw;
		}
	}
`;

interface PostProps {
	post: ArticlePage;
}

export const Post: FC<PostProps> = ({ post }) => {
	return (
		<Container
			key={post.path}
			width={post.frontMatter?.position?.frontPage?.[0]?.width || '100%'}
			rotation={post.frontMatter?.position?.frontPage?.[0]?.rotation || '0deg'}
			titlePosition={post.frontMatter?.position?.frontPage?.[0]?.titlePos}
		>
			<Link href={post.path} locale={post.meta.locale} passHref={true}>
				<a>
					{post.frontMatter.cover && (
						<Tilt options={{ max: 20, scale: 1 }}>
							<ImageStack images={post.frontMatter.cover} positions={post.frontMatter.position.frontPage} />
						</Tilt>
					)}
					<Title dangerouslySetInnerHTML={{ __html: post.frontMatter?.title }} />
				</a>
			</Link>
		</Container>
	);
};
