import React from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import styled from 'styled-components';
import { allLocales } from '@/utils/constants';

const LocaleLink = styled.a`
	background-color: var(--light-color);
	padding: 4px 8px;
`;

export const LocaleSwitcher = () => {
	const { locale, asPath, locales } = useRouter();
	const otherLocales = allLocales.filter((loc) => locales?.includes(loc.code) && loc.code !== locale);

	if (!otherLocales) return null;

	return (
		<>
			{otherLocales.map((otherLocale) => (
				<Link key={otherLocale.code} href={asPath} locale={otherLocale.code} passHref>
					<LocaleLink>{otherLocale.text}</LocaleLink>
				</Link>
			))}
		</>
	);
};
