import { screenSize } from '@/styles/ScreenSizes';
import React, { FC } from 'react';
import styled from 'styled-components';

interface ImageWrapperProps {
	imageCount: number;
}

export const ImageWrapper = styled.div<ImageWrapperProps>`
	position: relative;
	width: 100%;
	height: 100%;
	min-width: 0px;
	max-width: 100%;
	margin: ${(p) => p.imageCount * 10 + 16}px 16px 16px 80px;

	${screenSize.sm} {
		margin: ${(p) => p.imageCount * 10 + 6}px 8px 8px 16px;
	}
`;

interface StackImageProps {
	rotation: string;
	marginLeft: number;
	marginTop: number;
	width: string;
}

const StackImage = styled.figure<StackImageProps>`
	position: absolute;
	left: 0;
	top: 0;
	z-index: -1;
	margin: 0;
	width: ${(p) => (p.width ? p.width : 'auto')};

	transform: rotate(${(p) => p.rotation}) translate(${(p) => p.marginLeft}%, ${(p) => p.marginTop}%);

	&& img {
		transform: unset;
	}

	${screenSize.sm} {
		max-width: 100%;
		transform: rotate(${(p) => p.rotation}) translate(${(p) => p.marginLeft}%, 0);
	}
`;

interface ImageStackProps {
	images: string | string[];
	positions?: FrontPagePosition[];
	stackWidth?: string;
}

export const ImageStack: FC<ImageStackProps> = ({ images, positions, stackWidth }) => {
	const imageCount = typeof images === 'string' ? 1 : images.length;

	return (
		<ImageWrapper imageCount={imageCount}>
			{typeof images === 'string' ? <img src={images} alt="" /> : <img src={images[0]} alt="" />}
			{Array.isArray(images) &&
				images
					.filter((url, index) => index !== 0)
					.map((url, index) => (
						<StackImage
							rotation={positions?.[index + 1].rotation || (index + 1) * 5 + 'deg'}
							marginLeft={positions?.[index + 1].marginLeft || (index + 1) * -5}
							marginTop={positions?.[index + 1].marginTop || (index + 1) * -5}
							width={positions?.[index + 1].width || stackWidth || '100%'}
							key={index}
						>
							<img src={url} alt="" />
						</StackImage>
					))}
		</ImageWrapper>
	);
};
