import styled from "styled-components";
import { backgroundPattern } from "@/styles/styleConstants";

export const Background = styled.div`
${backgroundPattern};

position: fixed;
z-index: -1;

height: 100vh;
width: 100vw;
`;